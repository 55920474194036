.App, body {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #333;
  color: #bcbcbc;

}

.App .ring {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100vh;
}

.App .ring .viewer {
  margin: 1rem;
  padding: 1rem 0 2rem 0;
  background-color: #222;
  border-radius: 2rem;
}

hr {
  background-color: #333;
}

.App .ring .viewer .summary {
  margin: 1rem
}

.App .ring .viewer .bars {
display: grid;
grid-template-columns: 1fr 1fr 1fr;
margin: 1rem
}

.App .ring .viewer img {
  width: 100%;
}

a, a:visited {
  color: #fff;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 480px) {
  .App .ring .viewer .bars {
    display: flex;
    flex-direction: column;
  }
  .App .ring .viewer .bars > *{
    width: 100%;
  }
  .App .ring .viewer .bars span {
    margin-bottom: 1rem;
  }
}